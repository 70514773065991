<template>
  <div>
    <b-modal
      id="add-new-milking-sidebar"
      centered
      :visible="isAddNewMilkingSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      size="lg"
      backdrop
      no-header
      right
      :title="$t('milkingAdd')"
      :ok-title="$t('ok')"
      ok-only
      @ok="onSubmit(blankMilk)"
      @hidden="resetForm"
      @change="(val) => $emit('update:is-add-new-milking-sidebar-active', val)"
    >
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />
          <div v-for="milk in blankMilk" :key="milk.id" class="modal-form">
            <h6 class="mt-1">{{ milk }}</h6>
            <!-- milkingdatetime -->
            <validation-provider
              #default="validationContext"
              name="milkingdatetime"
              rules="required"
            >
              <b-form-group
                :label="$t('milkingDateTime')"
                label-for="milkingdatetime"
              >
                <b-form-datepicker
                  v-model="milk.milkingdatetime"
                  :locale="$i18n.locale"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- milkingdatetime -->
            <validation-provider
              #default="validationContext"
              name="time"
              rules="required"
            >
              <b-form-group :label="$t('milkingDayTime')" label-for="time">
                <b-form-timepicker v-model="milk.time" :locale="$i18n.locale" />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="milkingWhen"
              rules="required"
            >
              <b-form-group
                :label="$t('milkingDayTime')"
                label-for="milkingWhen"
              >
                <b-form-select
                  id="milkingWhen"
                  v-model="milk.wheninday"
                  :options="dayTime"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="earigno"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="milkingduration"
              rules="required"
            >
              <b-form-group
                :label="$t('milkingDuration')"
                label-for="milkingduration"
              >
                <b-form-input
                  id="milkingduration"
                  v-model="milk.milkingduration"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="earigno"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- amount -->
            <validation-provider #default="validationContext" name="amount">
              <b-form-group :label="$t('amount')" label-for="amount">
                <b-form-input
                  id="amount"
                  v-model="milk.amount"
                  autofocus
                  type="number"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Form Actions -->
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormDatepicker,
  BFormTimepicker,
  BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, getCurrentInstance } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserData } from "@/auth/utils";
import VueI18n from "vue-i18n";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    BFormDatepicker,
    BFormTimepicker,
    BFormSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewMilkingSidebarActive",
    event: "update:is-add-new-milking-sidebar-active",
  },
  props: {
    isAddNewMilkingSidebarActive: {
      type: Boolean,
      required: true,
    },
    selectedAnimal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    };
  },

  setup(props, { emit }) {
    const vm = getCurrentInstance();
    const dayJs = dayjs.extend(relativeTime);
    const blankMilkData = {
      id: 0,
      companyid: getUserData().companyid,
      plantid: store.state.app.selectedPlantId,
      animalid: props.selectedAnimal.id,
      milkingdatetime: dayJs().format(),
      milkingduration: 0,
      wheninday: 1,
      amount: 0,
      time:
        dayJs().get("hour").toString() +
        ":" +
        dayJs().get("minute").toString() +
        ":00",
    };
    const dayTime = [
      {
        text: vm.proxy.$t("morning"),
        value: 1,
      },
      {
        text: vm.proxy.$t("afternoon"),
        value: 2,
      },
      {
        text: vm.proxy.$t("evening"),
        value: 3,
      },
    ];

    const blankMilk = ref(
      Array.from({ length: getUserData().milkingCount ?? 3 }, (_, index) => {
        var currentMilkingTime =
          getUserData().milkingTimes != null
            ? getUserData().milkingTimes[index]
            : null;
        var times = dayJs(
          currentMilkingTime != null
            ? dayjs()
                .hour(currentMilkingTime.timemin.split(":")[0])
                .minute(currentMilkingTime.timemin.split(":")[1])
                .second(0)
            : index == 0
            ? dayJs().hour(9).minute(0).second(0)
            : index == 1
            ? dayJs().hour(12).minute(0).second(0)
            : dayJs().hour(18).minute(0).second(0)
        ).format("HH:mm:ss");
        // daytime calculation
        if (times < dayJs().hour(11).minute(0).second(0).format("HH:mm:ss")) {
          blankMilkData.wheninday = 1;
        } else if (
          times < dayJs().hour(16).minute(0).second(0).format("HH:mm:ss")
        ) {
          blankMilkData.wheninday = 2;
        } else {
          blankMilkData.wheninday = 3;
        }

        blankMilkData.time = times;
        return JSON.parse(JSON.stringify(blankMilkData));
      })
    );
    const resetblankMilk = () => {
      blankMilk.value = [JSON.parse(JSON.stringify(blankMilkData))];
    };

    const onSubmit = (milk) => {
      var datetime = dayjs(milk.milkingdatetime)
        .set("hour", milk.time.split(":")[0])
        .set("minute", milk.time.split(":")[1])
        .set("second", milk.time.split(":")[2])
        .format();

      milk.milkingdatetime = datetime;
      store.dispatch("animalsModule/addAnimalMilking", milk).then((res) => {
        if (res.statusdata == "-2") {
          vm.proxy.$toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t("animalMilkingAlreadyAdded"),
              icon: "AlertCircleIcon",
              variant: "alert",
            },
          });
        } else if (res.statusdata == "0") {
          vm.proxy.$toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t("animalMilkingAdded"),
              icon: "AlertCircleIcon",
              variant: "success",
            },
          });
          emit("refetch-data");
          emit("update:is-add-new-milking-sidebar-active", false);
        } else {
          vm.proxy.$toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t("animalMilkingNotAdded"),
              icon: "AlertCircleIcon",
              variant: "success",
            },
          });
        }
      });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetblankMilk);
    return {
      blankMilk,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      dayTime,
      dayjs,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-milking-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.sidebar-xl-scoped {
  width: 70rem;
}
</style>
