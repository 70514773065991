import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import VueI18n from "@/libs/i18n";

import dayjs from "dayjs";
import "dayjs/locale/tr";
import "dayjs/locale/en";
import "dayjs/locale/ru";
import "dayjs/locale/az";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refMilkingListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: "milkingdate",
      label: VueI18n.t("milkingDateTime"),
      sortable: false,
      formatter: (val) =>
        `${dayjs(val).locale(`${VueI18n.locale}`).format("DD MMMM YYYY dddd")}`,
    },
    {
      key: "milking1time",
      label: VueI18n.t("milking1Time"),
      sortable: false,
    },
    {
      key: "milking1duration",
      label: VueI18n.t("milking1Duration"),
      sortable: false,
      formatter: (val) => `${val} ${VueI18n.t("second")}`,
    },
    {
      key: "milking1amount",
      label: VueI18n.t("milking1Amount"),
      sortable: true,
      formatter: (val) => `${val} KG`,
    },
    {
      key: "milking2time",
      label: VueI18n.t("milking2Time"),
      sortable: false,
    },
    {
      key: "milking2duration",
      label: VueI18n.t("milking2Duration"),
      sortable: false,
      formatter: (val) => `${val} ${VueI18n.t("second")}`,
    },
    {
      key: "milking2amount",
      label: VueI18n.t("milking2Amount"),
      sortable: true,
      formatter: (val) => `${val} KG`,
    },
    {
      key: "milking3time",
      label: VueI18n.t("milking3Time"),
      sortable: false,
    },
    {
      key: "milking3duration",
      label: VueI18n.t("milking3Duration"),
      sortable: false,
      formatter: (val) => `${val} ${VueI18n.t("second")}`,
    },
    {
      key: "milking3amount",
      label: VueI18n.t("milking3Amount"),
      sortable: true,
      formatter: (val) => `${val} KG`,
    },

    { key: "delete", label: VueI18n.t("delete") },
    { key: "edit", label: VueI18n.t("edit") },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const allMilkings = ref([]);
  const filteredMilkings = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = filteredMilkings.value.length;
    const to = perPage.value * currentPage.value < localItemsCount;

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: to
        ? perPage.value * (currentPage.value - 1) + perPage.value
        : localItemsCount,
      of: allMilkings.value.length,
    };
  });

  const refetchData = () => {
    if (searchQuery.value) {
      filteredMilkings.value = allMilkings.value.filter((x) =>
        x.milkingname.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    } else {
      filteredMilkings.value = allMilkings.value;
    }
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData();
    }
  );

  const fetchMilkings = async (ctx) => {
    var milking = await store
      .dispatch("animalsModule/fetchAnimalMilking", {
        animalid: ctx,
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", { value: VueI18n.t("milkings") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    allMilkings.value = milking;
    filteredMilkings.value = [...milking];
    refetchData();

    return milking;
  };

  const deleteMilking = (ctx) => {
    store
      .dispatch("animalsModule/deleteAnimalMilking", ctx.milkingid)
      .then((response) => {
        fetchMilkings(ctx.animalid);
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("milkings") }),
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", { value: VueI18n.t("milkings") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------------------- UI -------------------------*
  // *===============================================---*

  return {
    fetchMilkings,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMilkingListTable,
    deleteMilking,
    filteredMilkings,
    allMilkings,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
