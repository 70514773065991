var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"edit-milking-sidebar","centered":"","visible":_vm.isEditMilkingSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","size":"lg","backdrop":"","no-header":"","right":"","ok-title":_vm.$t('ok'),"ok-only":"","title":_vm.$t('editAnimalMilking')},on:{"ok":function($event){return _vm.onSubmit(_vm.blankMilkingData)},"hidden":function($event){_vm.formValidation(_vm.resetblankmilking).resetForm},"change":function (val) { return _vm.changed(val); }}},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{ref:"refForm",staticClass:"p-2 modal-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();_vm.formValidation(_vm.resetblankmilking).resetForm}}},[_c('button',{ref:"submitButtonRef",staticClass:"d-none",attrs:{"type":"submit"}}),_c('validation-provider',{attrs:{"name":"time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('milking' + _vm.wheninday + 'Time'),"label-for":"time"}},[_c('b-form-timepicker',{attrs:{"locale":_vm.$i18n.locale},model:{value:(_vm.blankMilkingData['milking' + _vm.wheninday + 'time']),callback:function ($$v) {_vm.$set(_vm.blankMilkingData, 'milking' + _vm.wheninday + 'time', $$v)},expression:"blankMilkingData['milking' + wheninday + 'time']"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"milkingWhen","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('milkingDayTime'),"label-for":"milkingWhen"}},[_c('b-form-select',{attrs:{"id":"milkingWhen","options":_vm.dayTime,"autofocus":"","state":_vm.formValidation(_vm.resetblankmilking).getValidationState(
                  validationContext
                ),"trim":"","placeholder":"earigno"},model:{value:(_vm.wheninday),callback:function ($$v) {_vm.wheninday=$$v},expression:"wheninday"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"milkingduration","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('milking' + _vm.wheninday + 'Duration'),"label-for":"milkingduration"}},[_c('b-form-input',{attrs:{"id":"milkingduration","autofocus":"","state":_vm.formValidation(_vm.resetblankmilking).getValidationState(
                  validationContext
                ),"trim":"","placeholder":""},model:{value:(_vm.blankMilkingData['milking' + _vm.wheninday + 'duration']),callback:function ($$v) {_vm.$set(_vm.blankMilkingData, 'milking' + _vm.wheninday + 'duration', $$v)},expression:"blankMilkingData['milking' + wheninday + 'duration']"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"amount"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('milking' + _vm.wheninday + 'Amount'),"label-for":"amount"}},[_c('b-form-input',{attrs:{"id":"eamount","autofocus":"","type":"number","state":_vm.formValidation(_vm.resetblankmilking).getValidationState(
                  validationContext
                ),"trim":"","placeholder":""},model:{value:(_vm.blankMilkingData['milking' + _vm.wheninday + 'amount']),callback:function ($$v) {_vm.$set(_vm.blankMilkingData, 'milking' + _vm.wheninday + 'amount', $$v)},expression:"blankMilkingData['milking' + wheninday + 'amount']"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }