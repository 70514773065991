<template>
  <div>
    <b-modal
      id="edit-milking-sidebar"
      centered
      :visible="isEditMilkingSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      size="lg"
      backdrop
      no-header
      right
      :ok-title="$t('ok')"
      ok-only
      :title="$t('editAnimalMilking')"
      @ok="onSubmit(blankMilkingData)"
      @hidden="formValidation(resetblankmilking).resetForm"
      @change="(val) => changed(val)"
    >
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->

        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="formValidation(resetblankmilking).resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />

          <!-- milkingdatetime -->
          <validation-provider
            #default="validationContext"
            name="time"
            rules="required"
          >
            <b-form-group
              :label="$t('milking' + wheninday + 'Time')"
              label-for="time"
            >
              <b-form-timepicker
                v-model="blankMilkingData['milking' + wheninday + 'time']"
                :locale="$i18n.locale"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="milkingWhen"
            rules="required"
          >
            <b-form-group :label="$t('milkingDayTime')" label-for="milkingWhen">
              <b-form-select
                id="milkingWhen"
                v-model="wheninday"
                :options="dayTime"
                autofocus
                :state="
                  formValidation(resetblankmilking).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder="earigno"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="milkingduration"
            rules="required"
          >
            <b-form-group
              :label="$t('milking' + wheninday + 'Duration')"
              label-for="milkingduration"
            >
              <b-form-input
                id="milkingduration"
                v-model="blankMilkingData['milking' + wheninday + 'duration']"
                autofocus
                :state="
                  formValidation(resetblankmilking).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- amount -->
          <validation-provider #default="validationContext" name="amount">
            <b-form-group
              :label="$t('milking' + wheninday + 'Amount')"
              label-for="amount"
            >
              <b-form-input
                id="eamount"
                v-model="blankMilkingData['milking' + wheninday + 'amount']"
                autofocus
                type="number"
                :state="
                  formValidation(resetblankmilking).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTimepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { getUserData } from "@/auth/utils";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import dayjs from "dayjs";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTimepicker,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditMilkingSidebarActive",
    event: "update:is-edit-milking-sidebar-active",
  },
  props: {
    isEditMilkingSidebarActive: {
      type: Boolean,
      required: true,
    },
    milking: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankMilkingData: { ...this.milking },
      imgStr: "",
      formValidation: formValidation,
      profileFile: null,
      picturechanged: false,
      refInputEl: null,
      previewEl: null,
      inputImageRenderer: null,
      milkingtypeOptions: [],
      selectedMilkingType: null,
      dayTime: [
        {
          text: this.$t("morning"),
          value: 1,
        },
        {
          text: this.$t("afternoon"),
          value: 2,
        },
        {
          text: this.$t("evening"),
          value: 3,
        },
      ],
      wheninday: 1,
    };
  },
  mounted() {
    var { inputImageRenderer } = useInputImageRenderer(
      this.$refs.refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        this.picturechanged = true;
        this.$refs.previewEl.src = base64;
        this.imgStr = base64;
      }
    );
    this.inputImageRenderer = inputImageRenderer;
  },
  async created() {},
  methods: {
    async changed(val) {
      this.resetblankmilking();
      this.$emit("update:is-edit-milking-sidebar-active", val);
    },
    onSubmit(milk) {
      const blankMilkData = {
        id: milk.id,
        companyid: getUserData().companyid,
        plantid: milk.plantid,
        animalid: milk.animalid,
        milkingdatetime: dayjs(milk.milkingdatetime)
          .set("hour", milk["milking" + this.wheninday + "time"].split(":")[0])
          .set(
            "minute",
            milk["milking" + this.wheninday + "time"].split(":")[1]
          )
          .set(
            "second",
            milk["milking" + this.wheninday + "time"].split(":")[2]
          )
          .format(),
        milkingduration: milk["milking" + this.wheninday + "duration"],
        amount: milk["milking" + this.wheninday + "amount"],
        wheninday: this.wheninday,
      };

      store
        .dispatch("animalsModule/updateAnimalMilking", blankMilkData)
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-edit-milking-sidebar-active", false);
        });
    },
    async resetblankmilking() {
      this.blankMilkingData = { ...this.milking, milkingid: this.milking.id };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-milking-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
