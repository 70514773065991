<template>
  <div>
    <milking-list-add-new
      :is-add-new-milking-sidebar-active.sync="isAddNewMilkingSidebarActive"
      :selected-animal="selectedAnimal"
      :stock-medicines="stockMedicines"
      @refetch-data="fetchMilkings(selectedAnimal.id)"
    />
    <milking-list-edit
      :key="JSON.stringify(selectedMilking)"
      :is-edit-milking-sidebar-active.sync="isEditMilkingSidebarActive"
      :milking.sync="selectedMilking"
      @refetch-data="fetchMilkings(selectedAnimal.id)"
    />
    <animal-milk-chart :milkings="allMilkings" :animal="selectedAnimal" />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0 p-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("eachPage") }}</label>
            <v-select
              v-model="perPage"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label> {{ $t("piece") }} </label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-b-modal.animal-milkings-chart
                variant="success"
                class="d-flex justify-content-between align-items-center"
              >
                <feather-icon icon="BarChart2Icon" size="16" stroke-width="3" />
                <span class="text-nowrap">{{ $t("chart") }}</span>
              </b-button>
              <b-button
                class="ml-2"
                variant="primary"
                @click="
                  isAddNewMilkingSidebarActive = !isAddNewMilkingSidebarActive
                "
              >
                <span class="text-nowrap">{{ $t("milkingAdd") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refMilkingListTable"
        :responsive="true"
        :items="filteredMilkings"
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :per-page="perPage"
        :current-page.sync="currentPage"
      >
        <!-- Column: Milking -->
        <template #cell(milkingname)="data">
          <b-link @click="openEditSideBar(data)">
            <span class="align-middle ml-50 text-secondary">
              {{ data.item.milkingname }}
            </span>
          </b-link>
        </template>
        <template #cell(cinsname)="data">
          {{ data.item.cinsname == "Yoğun Yem" ? $t("grain") : $t("roughage") }}
        </template>
        <template #cell(delete)="data">
          <b-link
            @click="
              deleteMilking({
                animalid: selectedAnimal.id,
                milkingid: data.item.id,
              })
            "
          >
            <div class="d-flex">
              <feather-icon icon="TrashIcon" />
              <small class="align-middle ml-50 text-dark">{{
                $t("delete")
              }}</small>
            </div>
          </b-link>
        </template>

        <template #cell(edit)="data">
          <b-link @click="openEditSideBar(data)">
            <div class="d-flex">
              <feather-icon icon="EditIcon" />
              <small class="align-middle ml-50 text-dark">{{
                $t("edit")
              }}</small>
            </div>
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              <span class="text-muted"
                >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
                {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
                {{ $t("entries") }} </span
              ></span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="filteredMilkings.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BPagination,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useMilkingList from "./useMilkingList";
import AnimalMilkChart from "@/views/components/AnimalMilkChart.vue";
import MilkingListAddNew from "./AnimalListAddMilking.vue";
import MilkingListEdit from "./AnimalListEditMilking.vue";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";
import VueI18n from "@/libs/i18n";
export default {
  components: {
    MilkingListAddNew,
    MilkingListEdit,
    AnimalMilkChart,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BLink,
    BPagination,
    vSelect,
  },
  props: {
    selectedAnimal: {
      type: Object,
      required: true,
    },
  },
  computed: {
    stockMedicines() {
      return [{ osman: "osman" }];
    },
  },
  mounted() {},

  beforeRouteEnter(to, from, next) {
    if (to.name == "milkings") {
      to.meta.breadcrumb = [];

      to.meta.breadcrumb.push({
        text: VueI18n.t("milkings"),
        active: true,
      });
    }
    next();
  },
  setup(props, { emit }) {
    const isAddNewMilkingSidebarActive = ref(false);
    const isEditMilkingSidebarActive = ref(false);
    const selectedMilking = ref({});
    const milkingData = getUserData();
    const openEditSideBar = ({ item }) => {
      selectedMilking.value = item;
      isEditMilkingSidebarActive.value = !isEditMilkingSidebarActive.value;
    };

    const {
      fetchMilkings,
      tableColumns,
      perPage,
      currentPage,
      totalMilkings,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMilkingListTable,
      refetchData,
      allMilkings,
      filteredMilkings,

      // UI
      perPageOptions,

      // Extra Filters
      typeFilter,
      genderFilter,

      deleteMilking,
    } = useMilkingList();

    fetchMilkings(props.selectedAnimal.id);

    return {
      // Sidebar
      isAddNewMilkingSidebarActive,
      isEditMilkingSidebarActive,
      openEditSideBar,

      selectedMilking,

      fetchMilkings,
      tableColumns,
      perPage,
      currentPage,
      totalMilkings,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMilkingListTable,
      refetchData,
      perPageOptions,
      // Filter
      avatarText,
      // UI
      allMilkings,
      filteredMilkings,
      // Extra Filters
      typeFilter,
      genderFilter,
      deleteMilking,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
